import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    light: '#990AE3',
    dark: '#C86AF8',
    100: '#F9EEFE',
    200: '#F2DBFD',
    300: '#C86AF8',
    400: '#AF3CE8',
    500: '#990AE3',
    600: '#7907B3',
    700: '#570580',
    800: '#380354',
    900: '#1F012F',
  },
  gray: {
    100: '#FBFBFB',
    200: '#F2F2F2',
    300: '#CCC',
    400: '#A0A0A0',
    500: '#6E6E6E',
    600: '#5E5E5E',
    700: '#4E4E4E',
    800: '#303030',
    900: '#222',
  },
  red: {
    100: '#FFF5F9',
    200: '#FFE0EB',
    300: '#FF6699',
    400: '#FF3377',
    500: '#E4175C',
    600: '#BF134D',
    700: '#990F3D', //
    800: '#800D33', //
    900: '#590924', //
  },
  green: {
    100: '#F3FFF9', //
    200: '#D9FFEB', //
    300: '#40FF9F', //
    400: '#00F37A',
    500: '#00D066',
    600: '#00A552',
    700: '#007E3F', //
    800: '#00562B', //
    900: '#002F18', //
  },
  orange: {
    100: '#FFF7EB', //
    200: '#FFEDD0', //
    300: '#FFD06B', //
    400: '#FFB84D',
    500: '#FF9B00',
    600: '#DD8804',
    700: '#C47700', //
    800: '#9D5F00', //
    900: '#623C00', //
  },
  blue: {
    100: '#EDF8FF', //
    200: '#D4EEFF', //
    300: '#73C7FF', //
    400: '#40B2FF',
    500: '#0099FF',
    600: '#0082D9',
    700: '#006BB3', //
    800: '#00548C', //
    900: '#003D66', //
  },
};

const fontSizes = {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem",
  };

const theme = extendTheme({
  colors,
  fonts: {
    heading: 'TeliaSansHeading, Open Sans, sans-serif',
    body: 'TeliaSans, Raleway, sans-serif',
  },
  fontSizes,
  config: {
    initialColorMode: 'system',
    //useSystemColorMode: true,
  },
  semanticTokens: {
    colors: {
      primary: {
        default: 'brand.light',
        _dark: 'brand.dark',
      },
      brand: {
        default: 'brand.light',
        _dark: 'brand.dark',
      },
    },
  },
});

export default theme;
