import React, { Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Flex,
  Text,
  Box,
  Button,
} from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
import './App.css';
import { ErrorBoundary } from 'react-error-boundary';

import './i18n';
import theme from './themes/telia';

const Layout = React.lazy(() => import('./components/Layout'));

function ErrorFallback({ error, resetErrorBoundary }) {
  // User is not logged in
  if (error?.response?.status === 401) {
    return (
      <>
        <Alert
          status="warning"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          minHeight="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Not logged in any more
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Unfortunately your session has timed out. Reload this page to login
            again
          </AlertDescription>
        </Alert>

        <Flex m={4} justifyContent="center">
          <Button onClick={() => window.location.reload()} colorScheme="brand">
            Log in
          </Button>
        </Flex>
      </>
    );
  }

  // All other errors
  return (
    <>
      <Alert
        status="error"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        minHeight="200px"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {error.message}

          {error?.response?.config?.url && (
            <Box color="gray" fontWeight="normal" fontSize="xs">
              {error?.response?.config?.url} |{' '}
              {error?.response?.data?.error_message}
            </Box>
          )}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          Unfortunately its not possible to load the interface because of this
          error
        </AlertDescription>
      </Alert>

      <Flex m={4} justifyContent="center">
        <Button onClick={resetErrorBoundary} colorScheme="brand">
          Try again
        </Button>
      </Flex>
    </>
  );
}

function App() {
  const statuspage = window.location.pathname.startsWith('/checkstatus');

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <BrowserRouter basename={statuspage ? '/checkstatus/' : '/s/'}>
          <Suspense
            fallback={
              <Flex
                minH="100vh"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Spinner mb="4" />
                <Text>Loading dependencies</Text>
              </Flex>
            }
          >
            <Layout />
          </Suspense>
        </BrowserRouter>
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
